import React from 'react';
import Grid from '../Grid/Grid';
import { GridContentsContainer } from './GridContents.styles';
import { IGridContentsProps } from './GridContents.types';

const GridContents = ({ marginTop, marginBottom, gap, className, children, ...props }: IGridContentsProps): React.ReactElement => {
  return (
    <GridContentsContainer {...props} className={className} marginTop={marginTop} marginBottom={marginBottom} gap={gap}>
      <Grid gap={gap}>{children}</Grid>
    </GridContentsContainer>
  );
};

export default GridContents;
