export const NewCloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4032 11.9971L17.6976 7.71036C18.0892 7.31853 18.0892 6.68326 17.6976 6.29143C17.306 5.8996 16.6711 5.8996 16.2795 6.29143L11.9951 10.5882L7.71069 6.29143C7.31908 5.8996 6.68415 5.8996 6.29254 6.29143C5.90093 6.68326 5.90093 7.31853 6.29254 7.71036L10.5869 11.9971L6.29254 16.2839C6.10348 16.4715 5.99713 16.7269 5.99713 16.9934C5.99713 17.2598 6.10348 17.5152 6.29254 17.7028C6.48006 17.892 6.73533 17.9984 7.00162 17.9984C7.26791 17.9984 7.52317 17.892 7.71069 17.7028L11.9951 13.4061L16.2795 17.7028C16.467 17.892 16.7223 17.9984 16.9886 17.9984C17.2548 17.9984 17.5101 17.892 17.6976 17.7028C17.8867 17.5152 17.993 17.2598 17.993 16.9934C17.993 16.7269 17.8867 16.4715 17.6976 16.2839L13.4032 11.9971Z"
        fill="white"
      />
    </svg>
  );
};
